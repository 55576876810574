import styled, { keyframes } from "styled-components";
import { shake, fadeInUp, fadeIn } from "react-animations";

const errorShake = keyframes`${shake}`;
const errorFloatIn = keyframes`${fadeInUp}`;
const errorFadeIn = keyframes`${fadeIn}`;

export const Wrapper = styled.main`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 10% 80% 10%;
  height: 100vh;
`;

export const Logo = styled.span`
  grid-row: 3;
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  img {
    height: 100%;
    width: 35em;
  }
  @media (max-width: 500px) {
    grid-row: 2;
    img {
      height: 100%;
      width: 100%;
    }
    a {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-height: 500px) {
    margin-top: 10%;
  }
`;

export const BmwContainer = styled.div`
  grid-row: 6;
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5%;

  @media (max-width: 500px) {
    grid-row: 5;
    grid-column: 2;
    justify-content: center;
    padding-top: 5%;
  }

  @media (max-height: 500px) {
    grid-row: 5;
    grid-column: 2;
    justify-content: center;
    padding-top: 20%;
  }
`;

export const Bmw = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    height: 50%;
    width: 50%;
  }

  a {
    display: flex;
    justify-content: center;
  }
  @media (min-width: 500px) {
    img {
      height: 45%;
      width: 45%;
    }
  @media (max-width: 780px) {
    img {
      height: 20%;
      width: 20%;
    }
  }
`;

export const Text = styled.h2`
  color: snow;
  grid-row: 2;
  display: flex;
  justify-content: center;
  align-items: end;

  @media (max-width: 500px) {
    font-size: 1em;
    grid-column-start: 1;
    grid-column-end: 3;
    width: 65vw;
  }
`;


export const ErrorText = styled.p`
  grid-row: 5;
  grid-column: 2;
  color: snow;
  border: none;
  border-radius: 5px;
  background: #aa1212;
  width: 20vw;
  text-align: center;
  align-content: middle;
  justify-self: center;
  align-self: end;
  animation: 0.75s ${errorFloatIn};
  @media (max-width: 500px) {
    width: 20em;
    font-size: 3vw;
    margin-bottom: 2vh;
    text-align: center;
    animation: 0.75s ${errorFadeIn};
  }
`;

export const InnerWrap = styled.span`
  grid-row: 5;
  grid-column: 2;
  height: 3em;
  justify-self: center;
  animation: ${({ didError }) => (didError ? "0.6s " + errorShake : "none")};
  padding-bottom: 10%;

  @media (max-width: 500px) {
    grid-row: 4;
  }
`;

export const CanadaLogo = styled.div`
  color: snow;
  grid-row: 6;
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  align-items: flex-end;
  margin: auto auto 1% 1%;
  padding-top: 10%;
  img {
    height: 25%;
    width: 25%;
  }
  p {
    margin: 0px 0px 1% 2%;
    white-space: pre-line;
  }
  @media (max-width: 500px) {
    p {
      font-size: 0.2em;
    }
  }
`;

export const Warning = styled.p`
  color: Snow;
  @media (max-width: 500px) {
    font-size: 0.3em;
  }
`;

export const WarningContainer = styled.div`
  /* width: 80vw; */
  grid-row: 5;
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;

  @media (max-width: 500px) {
    font-size: 0.6em;
    margin-top: 15vh;
    grid-row: 4;
    grid-column-start: 1;
    grid-column-end: 4;
  }
`;

import React from 'react';
import ArticleCard from './ArticleCard';
import { Grid, NotFound } from './ListStyle';

const ArticleList = ({ articleList, onItemClick }) => {
  return (
    <Grid articleList={articleList}>
      {articleList.length > 0 ? (
        articleList.map(article => (
          <ArticleCard
            key={article.id}
            id={article.id}
            title={article.title}
            description={article.description}
            author={article.author}
            issue={article.magazineIssue}
            imageURL={article.images.headerUrl}
            onItemClick={onItemClick}
          />
        ))
      ) : (
        <NotFound>
          Please zoom the map out to see articles in this region
        </NotFound>
      )}
    </Grid>
  );
};

export default ArticleList;
import React, { Component } from 'react';
import { Container, Code } from './404Style';
import injector from '../shared/injector';

class Four extends Component {
  componentDidMount = () => {
    //injector(this);
  };

  render() {
    return (
      <div>
        <Container>
          <Code>404</Code>
        </Container>
      </div>
    );
  }
}

export default Four;

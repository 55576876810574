// import { injectGlobal } from "styled-components";
// import img from "../img/Skye.jpg";

// export default function injector(target) {
//   const { pathname } = target.props.location;
//   // takes pathname from component location
//   injectGlobal`
//     @import url('https://fonts.googleapis.com/css?family=Open+Sans');
//     html, body {
//       ${
//         pathname === "/"
//           ? `background: url(${img}) no-repeat center center fixed;`
//           : `background: snow;`
//       }
//       -webkit-background-size: cover;
//       -moz-background-size: cover;
//       -o-background-size: cover;
//       background-size: cover;
//       margin: 0px;
//       font-family: 'Open Sans', sans-serif;
//     }
//   `;
// }
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import img from '../img/Skye.jpg';

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Open+Sans');

html, body {
background: url(${img}) no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      margin: 0px;
      font-family: 'Open Sans', sans-serif;
   
  }
`;

export default GlobalStyle;
// const GlobalStyle = createGlobalStyle`
//   @import url('https://fonts.googleapis.com/css?family=Open+Sans');
//   html, body {
//     background: ${({ pathname }) =>
//     pathname === '/'
//       ? `url(${img}) no-repeat center center fixed`
//       : 'snow'};
//     -webkit-background-size: cover;
//     -moz-background-size: cover;
//     -o-background-size: cover;
//     background-size: cover;
//     margin: 0px;
//     font-family: 'Open Sans', sans-serif;
//   }
// `;

// export default function injector(target) {
//   const { pathname } = target.props.location;
//   console.log(pathname);
//   return (
//     <>
//       <GlobalStyle pathname={pathname} />
//       {target}
//     </>
//   );
// }
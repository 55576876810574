import React, { Component } from "react";

import {
  Wrapper,
  Logo,
  BmwContainer,
  Bmw,
  Text,
  InnerWrap,
  CanadaLogo,
  Warning,
} from "./HomeStyle";

import injector from "../shared/injector";
import LocationInput from "../shared/LocationInput";
import logo from "../shared/img/logo.png";
import bmw from "../shared/img/bmw-logo.png";
import canada from "../shared/img/canada-logo.png";

class Home extends Component {
  componentDidMount = () => {
    // injector(this);
  };

  render() {
    return (
      <Wrapper>
        <Logo>
          <a href="https://www.motorcyclemojo.com/">
            <img src={logo} alt="Mojo Magazine" />
          </a>
        </Logo>
        <InnerWrap didError={this.props.didError}>
          <LocationInput
            onLocationInputSubmit={this.props.onLocationInputSubmit}
            address={this.props.address}
            history={this.props.history}
          />
          <Text>To get started, enter a location</Text>
          <Warning>
            Please double check all routes prior to travelling when following one of our adventures as roads may be updated and renamed without warning.
          </Warning>
        </InnerWrap>

        <CanadaLogo>
          <img src={canada} alt="Canada" />
          <p>{`This project has been made possible
          in part by the Government of Canada`}</p>
        </CanadaLogo>

        <BmwContainer>
          <div>
            {/* <Text>Sponsored By:</Text>
            <Bmw>
              <a href="https://www.bmw-motorrad.ca ">
                <img src={bmw} alt="BMW" />
              </a>
            </Bmw> */}
            <Warning>Copyright © 2002 - 2024 Motorcycle Mojo Magazine</Warning>
          </div>
        </BmwContainer>
      </Wrapper>
    );
  }
}

export default Home;

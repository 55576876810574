import styled from 'styled-components';

export const SuggestionWrapper = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:600');
  font-family: 'Open Sans', sans-serif;
  border: none;
  display: inline-block;
  padding: 3px;
  text-align: left;
`;

export const Span = styled.span`
  margin-left: 5px;
  vertical-align: top;
`;

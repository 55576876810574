import styled, { css, keyframes } from 'styled-components';

const pinGrow = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2);
  }
`;

const textFade = keyframes`
  from {
    transform: translate(-50%, -50%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
`;

export const ArticleTitle = styled.h2`
  white-space: nowrap;
  padding: 0.3em;
  border-radius: 0.1em;
  display: inline-block;
  background: #272727;
  color: snow;
  position: absolute;
  animation: 0.4s ${css`${textFade}`} forwards;
  z-index: 99999;
`;

export const PinIcon = styled.img`
  cursor: pointer;
  position: absolute;
  width: ${({ size }) => size}em;
  left: -${({ size }) => size / 2}em;
  top: -${({ size }) => size * 1.5}em;
  transform-origin: 50% 100%;
  animation-timing-function: ease-in;
  animation: ${({ hover }) => (hover ? css`0.2s ${pinGrow} forwards` : 'none')};
  align-self: center;
`;
import React, { Component } from 'react';

import { Card, Container, Title, Description, Small } from './CardStlye';

class ArticleCard extends Component {
  render() {
    return (
      <Card onClick={() => this.props.onItemClick(this.props.id)}>
        <Container image={this.props.imageURL}>
          <Title>{this.props.title}</Title>
          <Description>{this.props.description}</Description>
          <Small>
            {this.props.author || 'Mojo Magazine'} · {this.props.issue}
          </Small>
        </Container>
      </Card>
    );
  }
}

export default ArticleCard;

import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Wrapper, Button, cssStyles } from './InputStyle';
import RenderSuggestion from './RenderSuggestion';
import { useNavigate } from 'react-router-dom';

const LocationInput = (props) => {
  const [address, setAddress] = useState('');
  const navigate = useNavigate();

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    setAddress(address);

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log('Success', { lat, lng });
        // Navigate to the new URL
        navigate(`/main/${lat}/${lng}`);
      })
      .catch(error => console.error('Error', error));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log('Success', { lat, lng });
          // Navigate to the new URL
          // navigate(`/main/${lat}/${lng}`);
        })
        .catch(error => console.error('Error', error));
    }
  };
  return (
    <Wrapper
      className="input-wrapper"
      onSubmit={(e) => {
        props.onLocationInputSubmit(address, navigate);
        if (props.updateZoom) {
          props.updateZoom();
        }
        e.preventDefault();
      }}
    >
      <Button>&nbsp;</Button>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'places-input location-search-input',
                onKeyPress: handleKeyPress, // Add the key press event listener

              })}
            />
            <div className="autocomplete-dropdown-container">
              {/* {loading && <div>Loading...</div>} */}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafac9', cursor: 'pointer' }
                  : { backgroundColor: '#ffffffc9', cursor: 'pointer' };
                return (
                  <div
                    key={suggestion.id || index} // Ensure each child has a unique key

                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <RenderSuggestion formattedSuggestion={suggestion.formattedSuggestion} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </Wrapper>
  );
};

export default LocationInput;
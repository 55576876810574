import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MainContainer } from './MainStyle';
import ArticleList from './ArticleList';
import Map from './Map';
import injector from '../shared/injector';
import { db } from '../shared/base';
import { collection, getDocs } from 'firebase/firestore';

let allArticles = [];
const Main = (props) => {
  const [articleList, setArticleList] = useState([]);
  const [canUpdate, setCanUpdate] = useState(false);
  const [duration, setDuration] = useState('All');
  const [bounds, setBounds] = useState({});
  const navigate = useNavigate();
  const { lat, lng } = useParams();

  useEffect(() => {
    const fetchArticles = async () => {
      allArticles = [];
      // grab all articles from db
      const articlesSnapshot = await getDocs(collection(db, 'articles'));
      articlesSnapshot.forEach((doc) => {
        allArticles.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setArticleList(allArticles);
      setCanUpdate(true);
    };

    fetchArticles();
    //injector(this);
  }, []);

  const onItemClick = (id) => {
    // redirects to article page onClick
    navigate(`/article/${id}`);
  };

  const onBoundsChange = ({ center, zoom, bounds, marginBounds }) => {
    // sets bounds of map, and then renders article pins
    navigate(`/main/${center.lat}/${center.lng}`);
    setBounds(bounds);
    filterArticles();
  };

  const onDurationChange = (val) => {
    // sets duration, then renders article pins
    setDuration(val.value);
    filterArticles();
  };

  const updateList = (map) => {
    // grabs bounds of map, then filters articles on set bounds on initial load
    if (canUpdate) {
      const boundsInit = map.geoService_.getBounds();
      const bounds = {
        nw: { lat: boundsInit[0], lng: boundsInit[1] },
        se: { lat: boundsInit[2], lng: boundsInit[3] },
        sw: { lat: boundsInit[4], lng: boundsInit[5] },
        ne: { lat: boundsInit[6], lng: boundsInit[7] },
      };
      setBounds(bounds);
      filterArticles();
      setCanUpdate(false);
    }
  };

  const filterArticles = () => {
    // updates articleList state
    setArticleList(filterByDuration(filterByBounds()));
  };

  const filterByBounds = () => {
    // if side effects are occurring, check if passing reference
    let filteredList = allArticles;
    filteredList = filteredList.filter((article) => {
      let { _lat } = article.location;
      let { _long } = article.location;

      let inLatBound = _lat <= bounds.nw.lat && _lat >= bounds.se.lat;
      let inLongBound = _long >= bounds.nw.lng && _long <= bounds.se.lng;
      return inLatBound && inLongBound;
    });
    return filteredList;
  };

  const filterByDuration = (currentList) => {
    if (duration !== 'All') {
      let filteredList = currentList.filter((article) => {
        if (article.duration) {
          // ensure article has duration for implicit error handling
          return article.duration === duration ? true : false;
        } else return false;
      });
      return filteredList;
    } else return currentList;
  };

  const locationTarget = {
    lat: parseFloat(lat) || 44.38935559999999,
    lng: parseFloat(lng) || -79.6903316,
  };

  return (
    <MainContainer>
      <Map
        history={props.history}
        locationTarget={locationTarget}
        didError={props.didError}
        onLocationInputSubmit={props.onLocationInputSubmit}
        address={props.address}
        duration={duration}
        articleList={articleList}
        onItemClick={onItemClick}
        onBoundsChange={onBoundsChange}
        onDurationChange={onDurationChange}
        updateListOnMount={updateList}
      />
      <ArticleList articleList={articleList} onItemClick={onItemClick} />
    </MainContainer>
  );
};

export default Main;
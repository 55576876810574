import React, { Component } from 'react';

import { PinIcon, ArticleTitle } from './PinStyle';

import MapPin from '../../../shared/img/MapPin.svg';

class Pin extends Component {
  state = {
    hovered: false,
  };
  render() {
    return (
      <div>
        {this.state.hovered && (
          <ArticleTitle>{this.props.articleTitle}</ArticleTitle>
        )}
        <PinIcon
          onMouseEnter={() => this.setState({ hovered: true })}
          onMouseLeave={() => this.setState({ hovered: false })}
          src={MapPin}
          size={2}
          hover={this.state.hovered}
          onClick={() => this.props.onItemClick(this.props.id)}
        />
      </div>
    );
  }
}

export default Pin;

// const firebase = require('firebase');
// // Required for side-effects
// require('firebase/firestore');
// // Initialize Firebase
// const firebaseApp = firebase.initializeApp({
//   apiKey: 'AIzaSyCGytDJw6rMlOKHqSRXftUO17qTKi_YguI',
//   authDomain: 'mojo-magazine.firebaseapp.com',
//   databaseURL: 'https://mojo-magazine.firebaseio.com',
//   projectId: 'mojo-magazine',
// });

// export const db = firebaseApp.firestore();
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: 'AIzaSyCGytDJw6rMlOKHqSRXftUO17qTKi_YguI',
  authDomain: 'mojo-magazine.firebaseapp.com',
  databaseURL: 'https://mojo-magazine.firebaseio.com',
  projectId: 'mojo-magazine',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(firebaseApp);
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../shared/base";
import {
  LinkTag, Advert,
  Title,
  Content,
  Header,
  SubHeader,
  Body,
  ImageBlock,
  UnavalibleContainer,
  PurchaseLink,
  Back,
  Footer,
  BottomBack,
  FooterText,
  WarningText,
} from "./ArticleStyle";
import img from "../shared/img/BMW-1600x200-F750GS.jpg";
import { collection, doc, getDoc } from 'firebase/firestore';

const Article = (props) => {
  const [article, setArticle] = useState({});
  const [shouldRender, setShouldRender] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const docRef = doc(collection(db, "articles"), id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setArticle(docSnap.data());
          setShouldRender(true);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchArticle();
  }, [id]);

  const validateDate = (targetDateString) => {
    if (targetDateString) {
      let currentDate = new Date();
      let targetDate = targetDateString.split(" ");
      return targetDate[1] === currentDate.getFullYear();
    } else return false;
  };

  const onBackButtonClick = () => {
    navigate(-1);
  };

  const art = article;
  const isNew = validateDate(art.magazineIssue);

  if (shouldRender)
    return (
      <div>
        <Header src={art.images.headerUrl}>
          <Back onClick={onBackButtonClick} />
          <Title> {art.title} </Title>
          <SubHeader>
            {art.author} · {art.magazineIssue}
          </SubHeader>
        </Header>
        {/* <LinkTag>
          <a href="https://www.bmw-motorrad.ca/en/shopping-tools/shop-inventory.html?tl=coo-adnw-psar-pro-miy-f90R-text-.-.-.-94785945bc73" className="motorlink">
            <Advert src={img} alt="bmw-motorrad" />
          </a>
        </LinkTag> */}
        <Body>
          <Content>&emsp;&emsp;&emsp;{art.content[0]} </Content>
          <ImageBlock src={art.images.img0} />
          <Content>&emsp;&emsp;&emsp;{art.content[1]} </Content>
          <ImageBlock src={art.images.img1} />
          {!isNew ? (
            <div>
              <Content>&emsp;&emsp;&emsp;{art.content[2]} </Content>
              <ImageBlock src={art.images.img2} />
              {art.content[3] && (
                <Content> &emsp;&emsp;&emsp;{art.content[3]} </Content>
              )}
              <LinkTag style={{

                paddingBottom: "5vh",
              }}>
                <PurchaseLink
                  style={{
                    marginTop: "5vh",

                  }}
                  href={art.buyUrl}
                >
                  Purchase this entire issue here!
                </PurchaseLink>
              </LinkTag>
            </div>
          ) : (
            <UnavalibleContainer>
              <PurchaseLink href={art.buyUrl}>
                Unlock Full The Article
              </PurchaseLink>
            </UnavalibleContainer>
          )}
        </Body>
        <Footer>
          <WarningText>
            Please double check all routes prior to travelling if following one
            of our article routes, as roads may be updated and renamed without
            warning
          </WarningText>
          <FooterText>
            Copyright © 2002 - 2024 Motorcycle Mojo Magazine
          </FooterText>
          <BottomBack onClick={onBackButtonClick} />
        </Footer>
      </div>
    );
  else return <div />;
};

export default Article;
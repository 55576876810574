import styled from 'styled-components';

export const Card = styled.div`
  cursor: pointer;
  color: white;
`;

export const Container = styled.div`
  background: ${({ image }) =>
    image
      ? ` linear-gradient(rgba(39,39,39, 0.5), rgba(39,39,39, 0.5)), url('${image}')`
      : `#272727`};
  /* position: relative;
  top: -100%; */
  background-size: cover;
  background-position: center;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1.2fr 0.1fr 0.2fr;
  grid-template-areas:
    'title'
    'description'
    '.'
    'footer';
`;

export const Title = styled.h1`
  grid-area: title;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 1.5em;
  text-shadow: -1px -1px 0 #272727, 1px -1px 0 #272727, -1px 1px 0 #272727,
    1px 1px 0 #272727;
`;

export const Description = styled.p`
  grid-area: description;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 1em;
  text-shadow: -1px -1px 0 #272727, 1px -1px 0 #272727, -1px 1px 0 #272727,
    1px 1px 0 #272727;
`;

export const Small = styled.small`
  grid-area: footer;
  padding: 5%;
  text-shadow: -1px -1px 0 #272727, 1px -1px 0 #272727, -1px 1px 0 #272727,
    1px 1px 0 #272727;
`;

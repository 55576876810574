import styled, { keyframes } from 'styled-components';
import { shake } from 'react-animations';
import ReactDropdown from 'react-dropdown';

const errorShake = keyframes`${shake}`;

export const Background = styled.div`
  width: 100%;
`;

export const ControlWrapper = styled.div`
  display: grid;
  grid-template-columns: 60% 20% 20%;
  width: 45%;
  min-height: 2.5em;
  border: none;
  position: absolute;
  top: 1em;
  left: 1em;
  @media (max-width: 500px) {
    position: relative;
    width: 100vw;
    top: -2.5em;
    left: 0em;
    grid-template-columns: 60% 40%;
    border-radius: 5px;
    .places-autocomplete-container {
      opacity: 1;
      width: 80vw;
    }
    .places-input {
      border-radius: 0px;
    }
    .input-wrapper {
      box-shadow: none;
      border-radius: 0px;
    }
  }
`;

export const InnerWrap = styled.span`
  grid-column: 1;
  margin-left: 1em;
  margin-right: 1em;
  animation: ${({ didError }) => (didError ? '0.6s ' + errorShake : 'none')};
  @media (max-width: 500px) {
    width: 105%;
    margin: 0px;
    border-radius: 0px;
  }
`;

export const MapDropdown = styled(ReactDropdown)`
  grid-column: 2;
  background: white;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
  vertical-align: middle;
  @media (max-width: 500px) {
    width: 100%;
    padding: 0px;
    margin-left: 0vw;
    border-radius: 0px;
    z-index: 1000;
    box-shadow: none;
    border-left: dashed;
  }
.Dropdown-placeholder{
margin-top:16px;}
  .Dropdown-control {
    padding: 0%;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    height: 100%;
  }
    .Dropdown-arrow-wrapper{   top:-29%;
    left: 85%; position:relative;   display: block;
    }

  .Dropdown-arrow {
    border-color: #272727 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    display: block;
    height: 0;
    position: relative;
    // top: -33%;
    // left: 85%;
    width: 0;
  }

  .Dropdown-menu {
    border-radius: 5px;
    opacity: 0.85;
    text-align: center;
    overflow-y: auto;
    position: absolute;
    background-color: white;
    width: 20%;
    z-index: 1000;
    @media (max-width: 500px) {
      width: 40%;
      opacity: 1;
    }
  }

  .Dropdown-option {
    padding: 3px 0px;
    cursor: pointer;
    color: #272727;
  }

  .Dropdown-option:hover {
    background: #f0f0f0;
    color: #272727;
  }
`;

export const MapOption = styled.option`
  padding: 0px;
  margin: 0px;
`;

import React, { Component } from 'react';
// import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import Home from './Home';
import Main from './Main';
// import Route from './Route';
import Article from './Article';
import Four from './404';
import GlobalStyle from './shared/injector';

class App extends Component {
  state = {
    address: '',
    location: {
      lat: 0.0,
      lng: 0.0,
    },
    didError: false,
  };

  handleLocationInputSubmit = (address, history) => {
    // History is passed back up from LocationInput for routing
    this.setState({ address, didError: false }, () =>
      this.attemptGeocode(history),
    );
  };

  attemptGeocode = async history => {
    // Once there is a valid location, redirect to /main/:location
    await geocodeByAddress(this.state.address)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        this.setState({ location: { lat, lng } }, () => {
          history.replace(
            `/main/${this.state.location.lat}/${this.state.location.lng}`,
          );
        });
      })
      .catch(error => {
        this.setState(prevState => {
          return { didError: true };
        });
      });
  };

  render() {
    return (
      <Router>
        <GlobalStyle />

        <Routes>
          <Route
            exact
            path="/"
            Component={Home}
            didError={this.state.didError}
            address={this.state.address}
            onLocationInputSubmit={this.handleLocationInputSubmit}
          />
          <Route
            exact
            path="/main/:lat/:lng"
            Component={Main}
            didError={this.state.didError}
            address={this.state.address}
            onLocationInputSubmit={this.handleLocationInputSubmit}
          />
          <Route exact path="/article/:id" Component={Article} />
          <Route Component={Four} />
        </Routes>
      </Router>
    );
  }
}

export default App;

import styled from "styled-components";
import logo from "../shared/img/logo.png";
export const LinkTag = styled.div
  ` background-color: white;`;
export const Back = styled.button`
  &:hover {
    filter: brightness(0.9);
  }
  background-image: url(${logo});
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  margin: 3vw;
  height: 5vw;
  width: 20vw;
  cursor: pointer;
  @media (max-width: 720px) {
    margin: 3vw 0 0 3vw;
    height: 12vw;
    width: 45vw;
  }
`;

export const Header = styled.div`
  /* display: grid; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)),
    url(${({ src }) => src});
  background-size: cover;
  background-position-x: center;
  width: 100%;
  min-height: 55vh;
  height: fit-content(100%);
`;

export const SubHeader = styled.div`
  color: snow;
  font-size: 2vw;
  padding: 3vw;
  @media (max-width: 720px) {
    font-size: 4vw;
  }
`;

export const Title = styled.h2`
  color: snow;
  font-size: 7vw;
  margin: 0px;
  padding-left: 3vw;
  padding-top: 20vh;

  @media (max-width: 720px) {
    font-size: 10vw;
    padding-top: 25vh;
  }
`;

export const Body = styled.div`
  padding-top: 3%;
background:white;
`;

export const Content = styled.p`
  font-size: 1em;
  margin-left: 10vw;
  margin-right: 10vw;
  @media (max-width: 720px) {
    font-size: 1.2em;
    margin-left: 6vw;
    margin-right: 6vw;
  }
`;

export const ImageBlock = styled.div`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 70vh;
  margin: 5vh 10vw;
  @media (max-width: 720px) {
    height: 30vh;
    margin: auto 3vw;
  }
`;

export const UnavalibleContainer = styled.div`
  position: relative;
  top: -30vh;
  height: 30vh;
  align-content: center;
  background-image: linear-gradient(
    rgba(255, 250, 250, 0),
    rgba(255, 250, 250, 0.4),
    rgba(255, 250, 250, 1)
  );
`;

export const PurchaseLink = styled.a`
  color: snow;
  display: block;
  background: #272727;
  border-radius: 7px;
  position: relative;
  padding: 3vh;
  top: 110%;
  margin-left: 25vw;
  margin-right: 25vw;
  text-align: center;
  margin-bottom:0;
  font-size: 5vh;
  text-decoration: none;
  @media (max-width: 720px) {
    margin-left: 10vw;
    margin-right: 10vw;
    font-size: 5vw;
  }
`;

export const Footer = styled.div`
  display: grid;
  grid-auto-rows: fit-content(100%);
  height: fit-content;
  width: 100%;
  padding-top: 3vh;
  background-color: #272727;
`;

export const FooterText = styled.small`
  grid-row: 3;
  text-align: center;
  color: snow;
  margin-bottom: 1%;
`;

export const WarningText = styled.p`
  grid-row: 1;
  text-align: center;
  color: snow;
`;

export const BottomBack = styled.button`
  grid-row: 2;
  align-self: center;
  justify-self: center;
  &:hover {
    filter: brightness(0.9);
  }
  background-image: url(${logo});
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
  height: 5vw;
  width: 20vw;
  @media (max-width: 720px) {
    height: 12vw;
    width: 35vw;
  }
`;

export const Advert = styled.img`
  width: 100%;
  background:white;
`;

import styled from 'styled-components';

export const Grid = styled.div`
  background-color: #272727;

  ${props =>
    props.articleList.length > 0 ?
      `
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: 0.33fr;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 55vw;
    `
      : null}

  @media (max-width: 500px) {
    grid-template-columns: none;
    grid-template-rows: repeat(3, 1fr);
    width: 100vw;
  }
`;

export const NotFound = styled.p`
  color: snow;
  font-size: 1.5em;
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45vh;

  @media (max-width: 500px) {
    font-size: 1.175em;
    display: flex;
    justify-content: center;
    margin-top: 10vh;
  }
`;

import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import { Background, InnerWrap, MapDropdown, ControlWrapper } from './MapStyle';

import LocationInput from '../../shared/LocationInput';
import Pin from './Pin';

class Map extends Component {
  componentDidUpdate = () => {
    this.props.updateListOnMount(this.map);
  };

  createMapOptions = maps => {
    return {
      disableDefaultUI: true,
      gestureHandling: 'greedy',
    };
  };

  updateZoom = () => {
    this.map.map_.zoom = 8;
  };

  render() {
    const options = [
      { value: 'All', label: 'All' },
      { value: 'Day', label: 'Day' },
      { value: 'Days', label: 'Days' },
      { value: 'Weeks', label: 'Weeks' },
    ];
    // console.log(this.props.locationTarget);
    return (
      <Background>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: ['AIzaSyAwgCrh7tcDiF8G_0eKYpZOd-Wy3p-EsfE'],
          }}
          center={[
            Number(this.props.locationTarget.lat),
            Number(this.props.locationTarget.lng),
          ]}
          options={this.createMapOptions}
          zoom={8}
          onChange={this.props.onBoundsChange}
          ref={input => {
            this.map = input;
          }}
        >
          {this.props.articleList &&
            this.props.articleList.map(article => (
              <Pin
                key={article.id}
                id={article.id}
                articleTitle={article.title}
                lat={article.location._lat}
                lng={article.location._long}
                onItemClick={this.props.onItemClick}
              />
            ))}
        </GoogleMapReact>
        <ControlWrapper>
          <InnerWrap didError={this.props.didError}>
            <LocationInput
              onLocationInputSubmit={this.props.onLocationInputSubmit}
              history={this.props.history}
              address={this.props.address}
              updateZoom={this.updateZoom}
            />
          </InnerWrap>
          <MapDropdown
            options={options}
            value={this.props.duration}
            onChange={this.props.onDurationChange}
          />
        </ControlWrapper>
      </Background>
    );
  }
}

export default Map;

import styled from 'styled-components';

export const Container = styled.div`
  color: #272727;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Code = styled.h1`
  font-size: 20em;
  @media (max-width: 700px) {
    font-size: 10em;
    padding-top: 25%;
  }
`;

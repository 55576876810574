import styled from 'styled-components';
import SearchIcon from '../img/SearchIcon.svg';

export const Wrapper = styled.form`
  display: grid;
  grid-template-columns: 10% 90%;
  width: 100%;
  max-width:540px;
  margin:0 auto;
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
  height: 50px;
  @media (max-width: 500px) {
    grid-template-columns: 15% 85%;
  }
  .places-autocomplete-container {
    border: none;
    width: 100%;
    opacity: 0.85;
    z-index: 1050;
    border-radius: 5px;
    position: absolute;
    top: 100%;
    background-color: white;
  }
  .places-input {
    display: inline;
    border: none;
    padding: 0%;
    padding-left: 2%;
    border-radius: 0px 5px 5px 0px;
    width: 98%;
    height: 50px;
  }
    .autocomplete-dropdown-container{
    position:relative;
    z-index:10;
    }
`;

export const Button = styled.button.attrs({
  type: 'submit',
})`
  &:hover {
    background-color: gainsboro;
  }
  grid-column: 1;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 5px 0px 0px 5px;
  background: url(${SearchIcon}) no-repeat white center;
  background-size: 50%;
  @media (max-width: 500px) {
    border-radius: 5px 0px 0px 5px;
  }
`;

// Not styled component styling but contained in this file for organizational clarity
export const cssStyles = {
  root: {
    gridColumn: '2',
    display: 'inline',
  },

};

import styled from 'styled-components';

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 45% 55%;
  height: 100vh;

  @media (max-width: 500px) {
    grid-template-columns: none;
    grid-template-rows: 40% 60%;
  }
`;
